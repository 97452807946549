
//@ts-nocheck
import Vue from "vue";
import {
	Advertiser,
	Category
} from "@/interfaces/advertiser";
import Alertize from "@/components/Alertize.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardTextFieldToolTip from "@/components/Content/CardTextFieldToolTip.vue";
import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength
} from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";
import CardActions from "@/components/Content/CardAction.vue";
//@ts-check

export default Vue.extend({
	name: "AdvertiserCreate",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		CardTextFieldToolTip,
		CardActions,
	},
	data: () => ({
		title: "Create",
		advertiser: {
			name: "",
			category_id: "",
			app_bundle: "",
			domain: "",
			active: true
		},
		valid: true,
		show_tooltip_app_bundle: false,
		show_tooltip_domain: false,
	}),
	created() {},
	async mounted() {
		await this.setLoadingData(TypeLoading.loading);
		await this.dispatchCategories();
		await this.setLoadingData();
	},
	computed: {
		getCategories(): Category[] {
			return this.$store.state.advertiser.categories;
		},
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength
			};
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		getError(index: any) {
			return getError(this.getErrors, index);
		},


		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{ root: true }
			);
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.create();
				await this.setLoadingData();
			} catch (error) {
				console.error("handleSubmit", { error: error });
				await this.setLoadingData();
			}
		},
		handleCancel() {
			this.$router.push({ name: "AdvertisersIndex" });
		},
		toggleTooltipAppBundle() {
			this.show_tooltip_app_bundle = !this.show_tooltip_app_bundle;
		},
		toggleTooltipDomain() {
			this.show_tooltip_domain = !this.show_tooltip_domain;
		},
		toggleStatus(status: boolean) {
			this.advertiser.active = Boolean(status);
		},
		async dispatchCategories() {
			return this.$store.dispatch("advertiser/getCategories", {
				root: true
			});
		},
		async create() {
			return this.$store.dispatch("advertiser/create", this.advertiser, {
				root: true
			});
		}
	}
});
